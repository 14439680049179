<template>
  <div>
    <Table
      label="Show Reels"
      :actions="actions"
      :fields="$store.getters['showReel/tableFields']"
      :items="$store.getters['showReel/tableData']"
      :detail="$store.getters['showReel/detailData']"
      :keyword="$store.state.showReel.tableKeyword"
      :pagination="$store.state.showReel.tablePagination"
      :loading="$store.state.showReel.loadingTable"
      :filter="$store.state.showReel.tableFilter"
      :sort="$store.state.showReel.tableSort"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: ['view'],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('showReel/getTableData')
    },
    filterData(val) {
      this.$store.commit('showReel/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('showReel/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('showReel/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('showReel/SET_TABLE_KEYWORD', val)
    },
    loadDetail(id) {
      return this.$store.dispatch('showReel/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('showReel/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('showReel/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('showReel/RESET_TABLE_FIELDS')
    },
  },
}
</script>
